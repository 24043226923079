<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('OFFERS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('OFFERS.HEADER.TEXT')}}</span>
        </p>
        <vs-select v-model="debitor_fk" autocomplete :label="$t('OFFERS.SELECT_DEBITOR')">
         <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in debitors" />
        </vs-select>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
            <OfferTable ref="offerTable" :DebitorFk="debitor_fk" :DebitorObjectFk="-1" v-on:ShowOffer="ShowOffer"></OfferTable>          
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import OfferTable from './components/tables/OfferTable';

export default {
  name: "Offers",
  components: {
      OfferTable
  },
    data: function (){
      return {
      offers:[],
      debitor_fk:0
      };
  },
  mounted () {
      if(this.debitors.length > 1 )
      {
        this.debitor_fk = this.debitors[0].value;
      }
    },
  computed: 
  { 
    debitors(){
      return this.GetListOfDebitorsForSelect();
    },
    getTableData() {
      var data = [];
      if(this.$store.state.accounting.offers.data != null)
      {
        data = this.$store.state.accounting.offers.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.accounting.offers.status) != 'undefined')
        loading = this.$store.state.accounting.offers.status.loading;
      return loading;
    }
  },
  watch: {
      debitor_fk(value)
      {
        this.$refs.offerTable.RefreshData(value,-1);
      },
      getTableData(value) {
          this.offers = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#offers_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#offers_table'});
        }
      }
    },
  methods: {  
    ...helperMethods,
    ...enumHelper,
    LoadData(){
      this.$refs.offerTable.RefreshData(this.debitor_fk,-1);
    },
    ShowOffer(data,editable){

      this.$store.dispatch('accounting/isOfferLocked', data.id)
      .then(resp => {
            
            var params = {id:data.id,locked:true,force:false};
            if(resp.isLocked == true)
            {
              if(this.$can('overwrite', 'admin'))
              {
                params.force = true;
              }
            }
            if((resp.isLocked == false) || (resp.isLocked == true && params.force == true))
            {
              if(editable == true)
              {
                this.$store.dispatch('accounting/lockOffer', params)
                .then(response => {
                  if(response.success == true)
                  {
                    this.$router.push({ name: 'Offer', params: { offer_fk: data.id, editable:editable, for_partnerorder_fk:0 } });
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':response.text}); 
                  }
                },
                error => {
                  var text = this.$t('OFFERS.LOCK_OFFER.ERROR').replace("*1*",error);
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
                });
              }
              else
              {
                this.$router.push({ name: 'Offer', params: { offer_fk: data.id, editable:editable, for_partnerorder_fk:0 } });
              }
            }
            else
            {
              var text = this.$t('OFFERS.OFFER_ALREADY_LOCKED').replace("{0}",resp.username);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
          }, 
          error =>{
              var text = this.$t('OFFERS.OFFER_LOCKED.ERROR').replace("*1*",error);
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':text});  
          });
    },
  }
};

</script>